





























import Vue from 'vue'
import Component from 'vue-class-component'
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import {required} from "vuelidate/lib/validators";
import SygniDatePicker from "@/components/inputs/SygniDatePicker.vue";
import {mapState} from "vuex";
import {SelectOption} from "@/store/types";
import {Prop} from "vue-property-decorator";
import {AddRegRepType} from "@/modules/genprox/modules/fund/modules/reg-reporting/store/types";
import SygniMultiSelect from "@/components/inputs/SygniMultiSelect.vue";
import {CreateRegRepDTO} from "@/modules/genprox/modules/AIFM/modules/reg-reporting/store/types";
import {Fund} from "@/modules/genprox/models/User";

Component.registerHooks(['validations'])
@Component({
  components: {
    SygniMultiSelect,
    SygniDatePicker,
    SygniRoundedButton, SygniSelect, SygniContainerTitle},
  computed: {...mapState('regReporting', {
    fundsOptions: (state: any) => {
      return state.funds.map( (fund: Fund) => { return {label: fund.name, value: fund.id} });
    }
    }),
    ...mapState('genprox', {
        fund: (state: any) => state.activeUserData.context,
      })
    }
})
export default class AddNewReport extends Vue {
  AddRegRepType = AddRegRepType;

  @Prop() pageType!: AddRegRepType;

  fundsOptions!: SelectOption<Fund>;
  fund!: Fund;

  selectedFunds: Array<Fund> = [];

  reportingYear: string = null;
  loading: boolean = false;

  get returnRouteName(): string {
    return this.pageType === AddRegRepType.FUND ? 'fund-regulatory-reporting' : 'AIFM-regulatory-reporting';
  }

  get isDisabledButton(): boolean {
    if(this.pageType === AddRegRepType.AIFM ){
      return !this.reportingYear || this.selectedFunds.length === 0;
    }
    return !this.reportingYear;
  }

  mounted(){
  }

  async createReporting(){
    this.loading = true;
    const data: CreateRegRepDTO = {
      fundIds: this.pageType === AddRegRepType.AIFM ?
          this.selectedFunds : [this.fund.id],
      year: Number(this.reportingYear),
      correctionNumber: 0,
      draft: false,
      data: null,
    }
    try{
      const newReportId: number = await this.$store.dispatch('regReporting/createReporting', data);
      await this.$store.dispatch('regReporting/getReport', newReportId);
      this.$notify({
        type: 'success',
        title: 'Success',
        text: 'New report has been successfully added'
      });
      await this.$router.push({name: this.returnRouteName, params: { openCardId: String(newReportId)}} );
    } catch(e){
      const errorMessage = this.$options.filters.errorHandler(e);
      this.$notify({
        type: 'error',
        title: 'An error occurred.',
        text: this.$t(errorMessage).toString()
      });
    }
    this.loading = false;
  }

  validations (){
    return  {
      product: {
        type: {required}
      }
    }
  }
}
