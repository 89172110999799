var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-new-investor-form"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('sygni-container-title', {
    staticClass: "mb-5"
  }, [_vm._v("Add new reporting")]), _vm.pageType === _vm.AddRegRepType.AIFM ? _c('sygni-multi-select', {
    staticClass: "secondary sygni-select--multi",
    attrs: {
      "options": _vm.fundsOptions,
      "label": 'CHOOSE FUNDS',
      "clearable": false,
      "validation": _vm.$v.product.type,
      "multiple": true,
      "close-on-select": false,
      "clear-on-select": false,
      "preserve-search": true
    },
    model: {
      value: _vm.selectedFunds,
      callback: function callback($$v) {
        _vm.selectedFunds = $$v;
      },
      expression: "selectedFunds"
    }
  }) : _vm._e(), _c('sygni-date-picker', {
    staticClass: "secondary",
    attrs: {
      "label": 'REPORTING YEAR',
      "validation": _vm.$v.product.agreementDate,
      "type": 'year',
      "format": 'YYYY'
    },
    model: {
      value: _vm.reportingYear,
      callback: function callback($$v) {
        _vm.reportingYear = $$v;
      },
      expression: "reportingYear"
    }
  }), _c('sygni-rounded-button', {
    staticClass: "outline gn-primary button secondary",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.isDisabledButton,
      "arrow-icon": true,
      "hoverable": true
    },
    on: {
      "click": _vm.createReporting
    }
  }, [_vm._v(" Create reporting ")]), _c('router-link', {
    attrs: {
      "to": {
        name: _vm.returnRouteName
      }
    }
  }, [_c('div', {
    staticClass: "cancel-button"
  }, [_vm._v("Cancel")])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }